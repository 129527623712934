@import "./font";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
